import axios from 'axios';
import { proxyUrlMode as currentEnv } from '../setupEnvironment';

const env = currentEnv === 'prod' ? 'api' : 'api-preprod';

const storeStatusOpened = 'OPEN';
const storeStatusUnopened = 'UNOPENED';
const storeStatusClosed = 'CLOSED';

const fetchStoreNumbers = (updatedCountry) => axios({
  method: 'GET',
  url: `https://${env}.nike.com/store/store_views/v2?search=((address.country==(${updatedCountry})) and (storeStatus==OPEN or storeStatus==UNOPENED or storeStatus==CLOSED))&fields=storeNumber,storeStatus&count=3000`,
  headers: {
    'nike-api-caller-id': 'com.nike:retail.sim',
  }
}).then(resp => {
  const data = resp?.data;
  const sortAlphaNum = (a, b) => a.localeCompare(b, 'en', {numeric: true});
  // eslint-disable-next-line array-callback-return
  const listOfStoreNumbers = [...new Set(data?.objects?.map((num) => {
    if ((num.storeStatus === storeStatusOpened)
    || (num.storeStatus === storeStatusUnopened)
    || (num.storeStatus === storeStatusClosed)) {
    return  num.storeNumber;
  }
  }))];
  listOfStoreNumbers.sort(sortAlphaNum);
  const numberArray = listOfStoreNumbers.map(number => ({
    value: number,
    label: number,
  }));
  return numberArray
});

export default fetchStoreNumbers;
